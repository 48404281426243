import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import PushNotification from './components/PushNotification';
import { AuthProvider, AuthContext } from './components/AuthContext';
import { messaging, getToken, onMessage } from './firebase';
import { Container } from '@mui/material';

const App = () => {
  const [currentToken, setCurrentToken] = useState(null);

  useEffect(() => {
    getToken(messaging, { vapidKey: 'YOUR_VAPID_KEY' }).then((token) => {
      if (token) {
        console.log('Current token:', token);
        setCurrentToken(token);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Handle the received message here (e.g., show notification or update UI)
    });
  }, []);

  return (
    <Router>
      <AuthProvider>
        <div style={{ display: 'flex' }}>
          <Sidebar />
          <div style={{ marginLeft: 240, width: '100%' }}>
            <Header />
            <div style={{ padding: 20 }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="/push-notification" element={<PrivateRoute><PushNotification /></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute><div>Settings Page</div></PrivateRoute>} />
              </Routes>
            </div>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
};

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  return user ? children : <Navigate to="/login" />;
};

export default App;
