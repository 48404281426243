// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCLsH0t8rZQbPTgsN9WB_-CmOQ3hebFN4c",
  authDomain: "push-test-reactapp.firebaseapp.com",
  projectId: "push-test-reactapp",
  storageBucket: "push-test-reactapp.appspot.com",
  messagingSenderId: "990442611039",
  appId: "1:990442611039:web:f37e75b50e8b5438c216ea",
  measurementId: "G-4M5H0QYGZS"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
