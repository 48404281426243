import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import axios from 'axios';
import { messaging, getToken } from '../firebase';

const PushNotification = () => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [currentToken, setCurrentToken] = useState(null);

  useEffect(() => {
    getToken(messaging, { vapidKey: 'BPgRnxN9n4-4HvoHvFyaCBY_JDOhmZC4ug_713DH8GD6L7_-ELqfrOIhVNbLYOFOF52-5iEQ7zhtUdLk8RVzfsw' }).then((token) => {
      if (token) {
        console.log('Current token:', token);
        setCurrentToken(token);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }, []);

  const handleSendNotification = () => {
    const payload = {
      title: notificationTitle,
      body: notificationBody,
      token: currentToken,
    };

    axios.post('http://localhost:3000/sendNotification', payload)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error sending notification:', error);
      });
  };

  const isButtonDisabled = !notificationTitle || !notificationBody || !currentToken;

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Send Push Notification
      </Typography>
      <TextField
        label="Notification Title"
        variant="outlined"
        fullWidth
        margin="normal"
        value={notificationTitle}
        onChange={(e) => setNotificationTitle(e.target.value)}
      />
      <TextField
        label="Notification Body"
        variant="outlined"
        fullWidth
        margin="normal"
        value={notificationBody}
        onChange={(e) => setNotificationBody(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSendNotification}
        disabled={isButtonDisabled}
      >
        Send Notification
      </Button>
    </Container>
  );
};

export default PushNotification;
