// src/components/Sidebar.js
import React, { useContext } from 'react';
import { List, ListItem, ListItemText, Drawer } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Sidebar = () => {
  const { user, logout } = useContext(AuthContext);

  if (!user) return null;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Dashboard" />
        </ListItem>
        {/* Entfernen Sie diesen Eintrag, wenn die Profile-Komponente nicht benötigt wird */}
        {/* <ListItem button component={Link} to="/profile">
          <ListItemText primary="Profile" />
        </ListItem> */}
        <ListItem button component={Link} to="/push-notification">
          <ListItemText primary="Send Push Notification" />
        </ListItem>
        <ListItem button component={Link} to="/settings">
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={logout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
