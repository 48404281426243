// src/components/Dashboard.js
import React from 'react';
import { Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Welcome to the Admin Dashboard
      </Typography>
      {/* Füge hier weitere Dashboard-Inhalte hinzu */}
    </div>
  );
};

export default Dashboard;
